module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"allPageHeaders":["Strict-Transport-Security: max-age=31536000; includeSubDomains; preload","Content-Security-Policy: default-src https: data: 'unsafe-inline'"],"headers":{"/*":["Referrer-Policy: strict-origin-when-cross-origin"]}},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","preconnect":["https://use.typekit.net"],"web":[{"name":"brother-1816","file":"https://use.typekit.net/zgt1zxn.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NKRFFNW","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Riverfront East","short_name":"Riverfront East","start_url":"/","background_color":"#fff","theme_color":"#4338ca","display":"browser","icon":"src/images/siteicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"649969bc0153b7e8d9d95f619ea0d922"},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
